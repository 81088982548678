body {
  background-color: rgb(36, 36, 36);
  color: aliceblue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'e-Ukraine-L';
}

a {
  color: inherit;
  text-decoration: none;
}

.slick-prev:before,
.slick-next:before {
  color: #a6c539 !important;
  font-size: 40px !important;
}

.slick-prev {
  left: -65px !important;
}

.slick-next {
  right: -45px !important;
}