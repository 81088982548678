.container {
    z-index: 1;
    background-color: #2E2C2C;
}

.holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    width: 100%;
    max-width: 1000px;
    margin-bottom: 120px;
    padding: 0 20px;
    margin-top: 80px;
    color: white;
}

.title {
    font-family: 'e-Ukraine Head';
    font-size: 30px;
    font-weight: 700;
}

.button {
    padding: 20px 50px;
    user-select: none;
    background-color: #A6C539;
    display: flex;
    align-self: self-start;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    font-size: 20px;
    font-weight: 500;
    font-family: 'e-Ukraine Head';
    border: 2px solid #A6C539;
    transition: .2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.button:hover {
    color: white;
    transition: .2s ease;
    background-color: transparent;
    cursor: pointer;
}

.buttonHolder {
    display: flex;
}

.separator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.ftpart {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 40px;

}

.ndpart {
    width: 50%;
}

.mobile {
    display: none;
}

.mobileHolder {
    display: none;
}

@media screen and (max-width: 1220px) {
    .separator {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .button {
        margin-top: 40px;
        text-align: center;
    }

    .ndpart {
        width: 70%;
        margin-top: 40px;
    }

    .ftpart {
        width: 100%;
    }

    .image {
        object-fit: contain;
        width: 100%;
    }

    .mobile {
        display: inline;
    }

    .desktop {
        display: none;
        width: auto;
    }

    .mobileHolder {
        display: inline;
    }
}

@media screen and (max-width: 500px) {
    
    .ndpart {
        width: 90%;
        margin-top: 40px;
    }
}