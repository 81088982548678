.container {
    color: aliceblue;
    z-index: 3;
    background-color: white;
    /* margin-left: auto;
    margin-right: auto; */
}

.galleryContainer {
    width: 100%;
    max-width: 700px;
    object-fit: contain;
    margin: auto;
    margin-top: 60px;
}

.titleHolder {
    display: flex;
    width: 100%;
    max-width: 960px;
    gap: 10px;
    align-items: center;
    /* justify-content: space-between; */
}

.galleryHolder {
    display: flex;
    align-items: center;
    /* margin-top: 100px; */
    width: 60%;
    max-width: 960px;
    gap: 10px;
}

.galleryTitle {
    word-wrap: normal;
    font-size: 20px;
    font-weight: 500;
    font-family: 'e-Ukraine Head';
}

.galleryText {
    font-size: 15.7px;
    color: rgb(161, 161, 161);
    width: 40%;
}

.buttonHolder {
    max-width: 700px;
    margin: auto;
    width: 90%;
}

.buttonform {
    padding: 20px 60px;
    user-select: none;
    background-color: #A6C539;
    display: flex;
    align-self: self-start;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    font-size: 26px;
    font-weight: 500;
    font-size: 20px;
    font-family: 'e-Ukraine Head';
    border: 2px solid #A6C539;
    transition: .2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    text-align: center;
}

.buttonform:hover {
    color: rgb(255, 255, 255);
    transition: .2s ease;
    background-color: transparent;
    cursor: pointer;
}

.index {
    color: aliceblue;
    text-align: center;
    margin-bottom: 40px;
}

.icon {
    background-color: rgba(240, 247, 255, 0.095);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 12px;
    color: rgb(186, 223, 52);
    font-size: 40px;
    font-weight: 900;
    backdrop-filter: blur(10px);
}

.galleryTextAdvanced {
    font-size: 15.7px;
    color: rgb(161, 161, 161);
    width: 100%;
    text-align: justify;
    text-justify: distribute-all-lines;
    text-align-last: left;
}

.imageContainer {
    height: 500px;
    border-radius: 8px;
    margin-top: 40px;
    margin-bottom: 40px;
    background-image: radial-gradient(radial, rgba(36, 36, 36, 0) 0%, rgba(36, 36, 36, 1) 40%);
    object-fit: cover;
}

.gallerySection {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1248px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.second {
    background-color: #2E2C2C;
}

.imageContainerAdvanced {
    height: 500px;
    border-radius: 2px;
    margin-bottom: 40px;
    background-image: radial-gradient(radial, rgba(36, 36, 36, 0) 0%, rgba(36, 36, 36, 1) 40%);
    object-fit: cover;
}

.lastIndex {
    margin-bottom: 80px;
}

.iconHolder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.addHolder {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.marg {
    margin-top: 100px;
}

.link {
    transition: .2s ease;
}

.link:hover {
    transition: .2s ease;
    color: rgb(186, 223, 52) !important;
}

.pin {
    transition: .2s ease;
}

.pin:hover {
    transition: .2s ease;
    animation: dizzy 1.2s infinite;
    cursor: pointer;
}

@keyframes dizzy {
    0% {
        transform: rotate(0deg) scale(1);
    }

    20% {
        transform: rotate(-15deg) scale(1.1);
    }

    40% {
        transform: rotate(15deg) scale(1.2);
    }

    60% {
        transform: rotate(-15deg) scale(1.2);
    }

    80% {
        transform: rotate(-15deg) scale(1.1);
    }

    100% {
        transform: rotate(0deg) scale(1);
    }
}

@media screen and (max-width: 1024px) {
    .container {
        width: 100%;
    }

    .gallerySection {
        width: 80%;
        display: block;
    }

    .imageContainer {
        margin-top: 0px;
        margin-bottom: 30px;
    }

    .galleryHolder {
        margin-top: 0px;
    }

    .galleryContainer {
        width: 100%;
    }

    .galleryText {
        width: 100%;
        margin-bottom: 40px;
    }

    /* .galleryTextAdvanced {
        margin-bottom: 40px;
    } */

}

@media screen and (max-width: 768px) {
    .marg {
        margin-top: 0px;
        padding-top: 40px;
    }

    .gallerySectionHolder {
        background-image: none;
    }

    .gallerySection {
        width: 85%;
        padding-top: 0;
    }

    .galleryContainer {
        width: 100%;
    }

    .titleHolder {
        /* flex-direction: column; */
        align-items: normal;
    }

    .galleryHolder {
        width: 100%;
        margin-bottom: 10px;
    }

    .iconHolder {
        justify-content: initial;
    }

    .container {
        margin-top: 0;
    }
}

@media screen and (max-width: 600px) {
    .titleHolder {
        width: 85%;
        margin: auto;
        margin-bottom: 30px;
    }
    

    .galleryHolder {
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
    }

    .galleryText {
        width: 70%;
        margin-bottom: 40px;
    }

    .gallerySection {
        width: 100%;
    }

    .galleryContainer {
        width: 100%;
    }

    .imageContainer {
        border-radius: 0px;
        width: 100%;
        height: auto;
    }

    .imageContainerAdvanced {
        border-radius: 0px;
        width: auto;
        height: 273.58px;
    }

    .index {
        margin-bottom: 40px;
    }

    .icond {
        display: none;
    }
}