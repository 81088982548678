.bgimage {
    width: 100%;
    height: 960px;
    background: rgba(16, 16, 16, 0.7);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: darken;
    backdrop-filter: blur(1px);
    position: absolute;
    top: 0;
    left: 0;
}

.maintitle {
    width: calc(100% - 40px);
    max-width: 1222px;
    max-height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    font-weight: 600;
    margin: auto;
    height: 100%;
    /* margin-top: 14%; */
    text-align: center;
    padding: 20px;
}

.videoContainer {
    width: 100%;
    height: 960px;
    object-fit: cover;
}

.video {
    width: 100%;
    height: 100%;
}

.container {
    z-index: 1;
    margin: auto;
    /* width: 100%; */
    max-width: 1551px;
    padding: 0 40px;
}

.holderContainer {
    z-index: 1;
    margin: auto;
}

.contentContainer {
    padding: 0 20px;
    border-bottom: 1px solid rgb(70, 70, 78);
    background: rgba(0, 0, 0, 0.101);
}

.navContainer {
    padding: 20px 100px;
    max-width: 1600px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.languagePicker {
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: 'e-Ukraine-UL Head';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    user-select: none;
    cursor: pointer;
    transition: .2s ease;
}

.languagePicker>span {
    transition: .2s ease;
}

.languagePicker:hover>span {
    transition: .2s ease;
    transform: scale(0.94);
}

.chevron {
    font-size: 20px;
    transition: .2s ease;
}

.chevronClicked {
    transform: rotate(180deg);
    transition: .2s ease;
}

.languageContainer {
    position: relative;
}

.picker {
    padding: 20px 0;
    z-index: 1000;
    position: absolute;
    top: 50px;
    right: 0;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    color: #5B5B5B;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    animation: showPicker;
    animation-timing-function: ease;
    animation-duration: .3s;
    -webkit-box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.07);
}

.langItem {
    user-select: none;
    transition: .2s ease;
    cursor: pointer;
    padding: 10px 40px;
}

.langItem:hover {
    transition: .2s ease;
    user-select: none;
    cursor: pointer;
    background-color: #5b5b5b28;
}

.pickerNone {
    display: none;
}

@keyframes showPicker {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.logoContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'e-Ukraine-UL Head';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 90px;
    margin-bottom: 150px;
}

.textHolder {
    /* width: 100%; */
    /* text-indent: 50px; */
    font-size: 15.7px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.title {
    font-size: 20px;
    font-family: 'e-Ukraine-UL Head';
    color: rgb(223, 223, 223);
    line-height: 24px;
    width: 55%;
    font-weight: 100;
}


.desc {
    line-height: 43px;
    font-size: 36px;
    font-family: 'e-Ukraine Head';
}

.imageHolder {
    position: relative;
    width: 48%;
    height: auto;
}

.imageContainer {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: contain;
}

.play {
    width: 113px;
    height: 113px;
    position: absolute;
    right: -30px;
    bottom: -65px;
    background-color: #A6C539;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    transition: .2s ease;
}

.play:hover {
    transition: .2s ease;
    transform: scale(0.9);
}

.arrow {
    position: absolute;
    right: 70px;
    bottom: -110px;
    z-index: 100;
}

.textadvice {
    position: absolute;
    right: 140px;
    bottom: -60px;
    font-family: 'e-Ukraine-UL Head';
    font-size: 14px;
    width: 240px;
}

.textContainer {
    width: 49%;
}

.arrow {
    font-size: 20px;
    margin-left: 5px;
    transition: .1s ease;
    font-weight: 800;
    transform: translateX(10px);
}


.buttonHolder {
    display: none;
}

.button {
    padding: 20px 20px;
    user-select: none;
    background-color: #A6C539;
    align-self: self-start;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    font-size: 26px;
    max-width: 200px;
    margin: auto;
    font-weight: 500;
    font-family: 'e-Ukraine Head';
    border: 2px solid #A6C539;
    transition: .2s ease;
    display: none;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.button:hover {
    color: aliceblue;
    transition: .2s ease;
    background-color: transparent;
    cursor: pointer;
}

.button:hover .arrow {
    transition: .1s ease;
    transform: translateX(0px);
}

.btnmobile {
    display: none;
}

@media screen and (max-width: 1024px) {

    .navContainer {
        padding: 20px 0;
    }

    .holder {
        display: block;
    }

    .maintitle {
        font-size: 32px;
        width: 80%;
    }

    .imageContainer {
        width: 100%;
    }

    .imageHolder {
        width: 100%;
    }

    .textadvice {
        font-size: 20px;
        width: 100%;
        max-width: 340px;
        left: 0;
    }

    .title {
        width: 100%;
        max-width: 400px;
    }

    .textContainer {
        margin-bottom: 40px;
        width: 100%;
    }

    .arrow {
        left: 45%;
        bottom: -130px;
        z-index: 100;
    }
}

@media screen and (max-width: 768px) {

    .desc {
        font-size: 23px;
        line-height: normal;
    }

    .title {
        font-size: 16px;
    }

    .maintitle {
        height: auto !important;
        margin-top: 200px;
    }

    .button {
        font-size: 16px;
        display: flex;

    }

    .videoContainer {
        height: 760px;
    }

    .bgimage {
        height: 760px;
    }

    .maintitle {
        height: 560px;
    }
}

@media screen and (max-width: 555px) {
    .langTitle {
        display: none;
    }

    .chevron {
        display: none;
    }

    .textadvice {
        font-size: 14px;
        max-width: 240px;
    }

    .arrow {
        bottom: -100px;
    }

    .btndesktop {
        display: none;
    }

    .play {
        width: 80px;
        height: 80px;
        bottom: -50px;
    }

    .btnmobile {
        display: block;
        margin-top: 150px;
    }

}